<template>
  <v-container fluid class="mt-8">
    <v-row>
      <v-col cols="12" v-if="permissions == 'merchant' && this.orders">
        <!-- <v-col cols="12"> -->
        <div>
    <v-sheet
      tile
      height="54"
      class="d-flex"
    >
      
    


      <v-select
        :v-model="this.$t(this.typeToLabel[this.type])"
        :items="i18nType"
        @change="changeFormat"
        dense
        outlined
        hide-details
        class="ma-2"
        :label="this.$t('common.type')"
      ></v-select>
      
      <v-spacer></v-spacer>

      

      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>

      </v-btn>

      <v-spacer></v-spacer>
      

      <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          

          <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

          <v-spacer></v-spacer>


      <v-select
        :v-model="this.$t(this.statusToLabel[this.status])"
        :items="i18nStatus"
        @change="updateEvents"
        :background-color="this.colors[this.indexStatus]"
        dense
        outlined
        hide-details
        class="ma-2"
        :label="this.$t('common.status')"
      >

      </v-select>
      
      
          
      
    </v-sheet>

    <v-sheet
      tile
      height="54"
      class="d-flex"
    >


    
    <button v-on:click="showShareDialog"><img src="../../assets/SyncCalendar.jpg" /></button>
    
    
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>


    <v-row align-content="center" justify="space-between">
      <v-card width="170" color='#d8e2f3' >
        <div class="text-center">{{ this.$t("order.waiting_booking") }}</div>
            
          </v-card>

      <v-card width="170" color='#ffa9a3' >
        <div class="text-center">{{ this.$t("order.waiting_payment") }} ({{this.noTotalWaitingForPayment}})</div>
            
          </v-card>

    <v-card width="170" color='#ffd966'  >
      <div class="text-center">{{ this.$t("order.waiting_confirm") }} ({{this.noTotalWaitingForConfirm}}) </div>
            
          </v-card>

    <v-card width="170" color='#c3e0b2'  >
      <div class="text-center">{{ this.$t("order.confirmed") }} ({{this.noTotalConfirmed}})</div>
            
          </v-card>
    </v-row>

    
    </v-sheet>


    <v-sheet :height="this.type === 'month' ? 800 : 1300">
      <v-calendar
        ref="calendar"
        v-model="value"
        event-text-color="black"
        :show-month-on-first=true
        :show-week=true
        :locale="(this.lang == 'tc') ? 'zh-hk':'en'"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        @click:more="viewDay"
        @click:date="viewDay"
        @click:event="showEvent"
        
      >
      
      <template v-slot:event="{ event }">
        <div class="v-event-draggable">
          <strong>{{ event.name }}</strong><br>
          <strong>人數: ({{ event.noPeople }}), ${{event.price}}</strong><br>
          
          {{ formatEventTime(event.start) }} - {{ formatEventTime(event.end) }} <br>
          <strong>{{ event.teacher }}</strong><br>
          <div v-if="event.status === 'waitingForConfirm' || event.status === 'waitingForPayment' || event.status === 'confirmed'">
              <strong>{{ event.username }}</strong><br>
              <strong>{{ event.paymentMethod }}</strong><br>
          </div>
          <div v-else></div>
          
        </div>
      </template>
      <template v-slot:day-label-header="{date}">{{date}}</template>
      
      </v-calendar>
    </v-sheet>



    <v-dialog
      v-model="selectedOpen"
      max-width="450"
    >
      <v-card class="pa-4" :color="this.selectedEvent.color">
        <v-card-title class="text-h5">
          {{this.selectedEvent.name}}
        </v-card-title>
          <strong>{{this.$t(this.statusToLabel[this.selectedEvent.status])}}</strong><br>
          
         <strong>人數: ({{ this.selectedEvent.noPeople }}), ${{this.selectedEvent.price}}</strong><br>
          
          {{ formatEventTime(this.selectedEvent.start) }} - {{ formatEventTime(this.selectedEvent.end) }} <br>
          <strong>{{ this.selectedEvent.teacher }}</strong><br>
          <div v-if="this.selectedEvent.status === 'waitingForConfirm' || this.selectedEvent.status === 'waitingForPayment' || this.selectedEvent.status === 'confirmed'">
              <strong>{{ this.selectedEvent.username }}</strong><br>
              <strong>{{ this.selectedEvent.paymentMethod }}</strong><br>
          </div>
          <div v-else></div>

          <div style="height: 20px;"></div>

        <v-card-actions>


          <div v-if="this.selectedEvent.status === 'waitingForConfirm'">
              <v-btn
              class="mr-1"
                color="white"
                
                outlined
                @click="viewOrder"
              >
                查看訂單
              </v-btn>
              <v-btn
              class="mr-1"
                color="white"
                
                outlined
                @click="onAcceptOrderPressed"
              >
                確認訂單
              </v-btn>
          </div>
          <div v-else></div>

          <div v-if="this.selectedEvent.status === 'waitingForConfirm' || this.selectedEvent.status === 'waitingForPayment' || this.selectedEvent.status === 'confirmed'">
              <v-btn
              class="mr-1"
                color="white"
                
                outlined
                @click="openChatRoom"
              >
                聯絡客人
              </v-btn>
          </div>
          <div v-else></div>

          <div v-if=" this.selectedEvent.status === 'waitingForPayment' && (this.selectedEvent.mode == 'customtime' || this.selectedEvent.mode == 'allcustom') ">
            <v-btn
              class="mr-1"
                color="white"
                
                outlined
                @click="viewOrder"
              >
              更改預約時間
              </v-btn>
          </div>
          <div v-if=" this.selectedEvent.status === 'waitingForPayment'">
              <v-btn
              class="mr-1"
                color="white"
                
                outlined
                @click="onConfirmTimePressed"
              >
              確認預約時間
              </v-btn>
          </div>
          <div v-else></div>
          
          

          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isShareShown"
      max-width="400"
    >
      <v-card class="pa-4">
        <v-card-title class="text-h5">
          {{this.$t('global_alert.title_share_calendar')}}
        </v-card-title>
          <strong>{{this.$t('global_alert.share_calendar_warning')}}</strong><br>
          
          <div style="height: 20px;"></div>
         <v-text-field
              v-model="emailShare"
              label="Email"
              
          />
          <div style="height: 20px;"></div>

        <v-card-actions>


          <v-btn
              class="mr-1"
                color='#c3e0b2'
                outlined
                @click="onShareCalendarPress"
              >
                {{this.$t('common.confirm')}}
              </v-btn>
              <v-spacer></v-spacer>
              {{this.textLoadingShare}}
              <v-spacer></v-spacer>
          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <FormDialog
        ref="dialog"
        @updateStatus="updateDialogStatus"
        :merchantsList="getMerchantList"
        :userList="[]"
        :serviceList="[]"
        :packageList="[]"
        :sessionList="[]"
        :paymentMethodList="this.paymentMethodList.length == 0 ? [] : this.handledPaymentMethodList"
        :i18nStatus="i18nStatusNew"
    />
    <ChatRoom ref="chatroom"/>

  </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import api                      from '@/api'
import FormDialog               from "@/views/Order/OrderDialog"
import ChatRoom                 from "@/views/Message/ChatRoom"
var validator = require("email-validator");

export default {
  components : {
    FormDialog,
    ChatRoom,
  },
  mounted () {
    this.getData();
    
  },
  computed : {
    ...mapState(['lang', 'user', 'permissions', 'stateMerchantId']),
    i18nType(){
            let result = this.typei18
            return result.map(item => item.text = this.$t(item.i18n))
        },
    
    i18nStatus(){
        let result = this.statusi18
        return result.map(item => item.text = this.$t(item.i18n))
    },

    LastWeekDate () {
      return this.$moment().subtract(7, 'd')
    },
    nextWeekDate () {
      return this.$moment().add(7, 'd')
    },
    next30D () {
      return this.$moment().add(30, 'd')
    },
    LastBeforeWeekDate () {
      return this.$moment().subtract(14, 'd')
    },
    LastSixMonths () {
      return this.$moment().subtract(6, 'months')
    },
    handledPaymentMethodList () {
      let result = this.paymentMethodList

      result.map(item => {
        item.name = item[(this.lang == 'tc') ? 'nameZh' : 'nameEng']
      })

      return result
    },
    i18nStatusNew () {
      let result = this.statusArr

      result.map(item => {
        item.text = this.$t(item.i18n)
      })

      return result
    },
    getMerchantList() {
      if(Object.keys(this.selectedEvent).length == 0){
        return [];
      } else {
        if(this.selectedEvent.status === 'waitingForConfirm' || this.selectedEvent.status === 'waitingForPayment'){
          return [this.selectedEvent.order.merchantId];
        } else {
          return [];
        }
      }
    },
    next30DService () {
      let resultArr = []

      if (this.orders) {
        let tmpArr = []
        this.orders.map(item => {
          if (item.status == 'confirmed' && (this.$moment() <= this.$moment(item.serviceDateTimeId.startAt) && this.next30D >= this.$moment(item.serviceDateTimeId.startAt))) {
            // if(item.status == 'confirmed' ){
            let startTime = this.$moment(item.serviceDateTimeId.startAt).format('HH:mm')
            let endTime = this.$moment(item.serviceDateTimeId.endAt).format('HH:mm')
            let startDay = this.$moment(item.serviceDateTimeId.startAt).format('YYYY/MM/DD')
            let weekday = this.$moment(item.serviceDateTimeId.startAt).locale((this.lang == 'tc') ? 'zh-hk' : 'en-il')
                              .format('dddd');
            let serviceName = item.serviceId.name
            let langName = (this.lang == 'tc') ? 'nameZh' : 'nameEng'

            if (!tmpArr[startDay]) {
              tmpArr[startDay] = [{
                teacher   : item.teacher[langName],
                timeslot  : startTime + ' - ' + endTime,
                weekday   : weekday,
                startTime : startTime,
                endTime   : endTime,
                name      : serviceName
              }]
            } else {
              let index = tmpArr[startDay].length - 1

              if (tmpArr[startDay][index].teacher == item.teacher[langName] && tmpArr[startDay][index].name == serviceName) {
                if (tmpArr[startDay][index].startTime != startTime && tmpArr[startDay][index].endTime != endTime) tmpArr[startDay][index].timeslot += ', ' + startTime + ' - ' + endTime
              } else {
                tmpArr[startDay].push({
                  teacher   : item.teacher[langName],
                  timeslot  : startTime + ' - ' + endTime,
                  startTime : startTime,
                  weekday   : weekday,
                  endTime   : endTime,
                  name      : serviceName
                })
              }
            }
          }
        })

        resultArr = tmpArr
      }
      return resultArr
    }
  },

  methods  : {
    ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
    formatEventTime(date) {
      return new Date(date).toLocaleTimeString('en-US', {
        hour: "2-digit", 
        minute: "2-digit", 
        hour12: false
      })
    },
    viewDay ({ date }) {
      console.log("viewDay "+date);
      
      this.value = date
      this.type = 'day'
    },
    howTypeFormat(temp){
        return this.typei18.find( item => this.$t(item.i18n) == temp).value
    },
    howStatusValue(temp){
      //console.log("howStatusValue "+temp)

      return this.statusi18.find( item => this.$t(item.i18n) == temp).value
    },

    howStatusText(){
        return this.statusToLabel[this.status];
    },
    changeFormat (date) {
      console.log(date);
      
      this.type = this.howTypeFormat(date);
    },
    convertExtraTime(extra, sdt, isStart) {
      var original = new Date(sdt.startAt);
      if(extra){
        var locale = new Date(original.getTime() + 8*60*60*1000);
        var newstart = new Date(locale.toISOString().substring(0,10) + "T" +extra["startAt"]+":00"); 
        if(isStart){
          return newstart;
        } else {
          var newend = new Date(newstart.getTime() + parseFloat(extra["duration"]) * 60 * 60 * 1000);
          return newend;
        }

      } else {
        if(isStart){
          return original;
        } else {
          return new Date(sdt.endAt);
        }
      }
    },
    viewOrder() {
      
      this.$refs.dialog.open('edit', this.selectedEvent.order, 'service')
    },
    async openChatRoom () {
      var userId = this.selectedEvent.order.userId._id;
      var userName = this.selectedEvent.username;
      
      let findConversationId = await api.get('/message/findMyConversationIdWithUserId/' + userId)
      if (findConversationId.data) {
        console.log(findConversationId);
        this.$refs.chatroom.open(findConversationId.data.conversationId, userName, userId)
      }
    },
    showEvent ({ nativeEvent, event }) {
      
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          //console.log(nativeEvent);
          //console.log(event);
          this.selectedOpen = true
          //requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          //requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      showShareDialog () {

        if (this.isShareShown) {
          this.isShareShown = false
        } else {
          this.isShareShown = true
        }

      },
    getData () {
      
      this.getAllService()
      this.getAllPaymentMethod()
      
      
      
    },
    async getAllService() {
          try{
              let result = await api.get('/services/all')
              //console.log(result)

              if(result){
                var json = {};
                result.data.map(item => {
                    var id = item._id;
                    json[id] = item;
                })
                  this.serviceList = json
                  var merchantId = result.data[0]["merchantId"]
                  this.getAllServiceDateTime(merchantId)
              }
          } catch (error) {
              this.PUSH_GLOBAL_SNACK_MESSAGES({
                  message: error.response.data.message,
                  icon   : 'mdi-close',
              })
          }
      },

      async getAllServiceDateTime(merchantId) {
          try{
            console.log(merchantId)
              let result = await api.get('/serviceDateTimes/available-by-merchant/'+merchantId)
              
              //console.log(result)

              if(result){
                  var dataArray = result.data;
                  
                  this.serviceDateTimeList = dataArray["serviceDateTimes"];
                  
                  this.resetServiceDateTimeList();
                  
                  //console.log(info)

                  this.getAllServiceOrder();
                  
              }
          } catch (error) {
              this.PUSH_GLOBAL_SNACK_MESSAGES({
                  message: error.response.data.message,
                  icon   : 'mdi-close',
              })
          }
      },

      async resetServiceDateTimeList() {
        var info = {};
                  this.serviceDateTimeList.map(item => {
                    
                        var slotid = item._id;
                        
                        var serviceId = item.serviceId;
                        var service = this.serviceList[serviceId];
                        
                      
                        info[slotid] = {
                          "name": service.name,
                          "teacher": "",
                          "teacherId": service.teacher,
                          "total": item.quantity,
                          "start": new Date(item.startAt),
                          "end": new Date(item.endAt),
                          "price": item.price,
                          "serviceId": item.serviceId,
                          "noWaitForPayment": 0,
                          "noWaitForConfirm": 0,
                          "noConfirmed": 0,
                          "isEmpty": true,
                          "published": service.published && item.published ? true : false,
                        };

                      })

                      

                  this.infoSlot = info;
      },

      getOrderMode(item){
        var mode = "allfixed";
        if(item.extra != null){
          mode = item.extra.mode;
        }
        return mode;
      },

    async getAllServiceOrder () {
      try {
        let result = await api.get('/orders/all')
        //console.log(result);

        if (result) {
          let resultArr = result.data
          this.orders = result.data
          this.totalServiceOrder = resultArr.length

          this.resetCountOrder();

                    
          this.updateEvents(this.status);

          
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
    },
    
      async resetCountOrder() {
        var tempTotalWaitingForPayment = 0;
        var tempTotalWaitingForConfirm = 0;
        var tempTotalConfirmed = 0;

        this.orders.map(item => {
                var slotid = item.serviceDateTimeId["_id"];
                var info = this.infoSlot[slotid];
                var serviceId = info.serviceId;
                var noWaitForPayment = info["noWaitForPayment"];
                var noWaitForConfirm = info["noWaitForConfirm"];
                var noConfirmed = info["noConfirmed"];
                
                if(item.status == "waitingForPayment") {
                  noWaitForPayment = noWaitForPayment + item.amount;
                  tempTotalWaitingForPayment++;
                } 
                if(item.status == "waitingForConfirm") {
                  noWaitForConfirm = noWaitForConfirm + item.amount;
                  tempTotalWaitingForConfirm ++;
                } 
                if(item.status == "confirmed") {
                  noConfirmed = noConfirmed + item.amount;
                  tempTotalConfirmed++;
                } 

                var name = (this.lang == 'tc') ? item.serviceId.teacher.nameZh : item.serviceId.teacher.nameEng;
                if(this.teacherNameList[serviceId] == null) {
                  this.teacherNameList[serviceId] = name
                }

                

                if(item.status != "cancelled") {
                  info["teacher"] = name;
                  info["noWaitForPayment"] = noWaitForPayment;
                  info["noWaitForConfirm"] = noWaitForConfirm;
                  info["noConfirmed"] = noConfirmed;
                  info["isEmpty"] = false;
                  this.infoSlot[slotid] = info;
                }

                
        })

              this.noTotalWaitingForPayment = tempTotalWaitingForPayment;
              this.noTotalWaitingForConfirm = tempTotalWaitingForConfirm;
              this.noTotalConfirmed = tempTotalConfirmed;
      },
    
    
    async updateEvents (newstatus) {
      console.log(newstatus);
      
      var thisStatus = newstatus;
      if(newstatus == undefined) thisStatus = this.status;
      if(newstatus == null) thisStatus = this.status;
      if(this.statuses.indexOf(thisStatus) == -1){
        thisStatus = this.howStatusValue(thisStatus);
      }
      console.log("updateEvents "+thisStatus);
      
        const events = []
        
        var indexStatus = this.statuses.indexOf(thisStatus);
        if(indexStatus == -1) indexStatus = 0;
        this.indexStatus = indexStatus;
        

        var isAddWaitingForBooking = indexStatus == 1;
        var isAddWaitingForPayment = indexStatus == 2;
        var isAddWaitingForConfirm = indexStatus == 3;
        var isAddConfirmed = indexStatus == 4;

        if(indexStatus == 0){
          isAddWaitingForBooking = true;
          isAddWaitingForPayment = true;
          isAddWaitingForConfirm = true;
          isAddConfirmed = true;
        }

        if(isAddWaitingForBooking){
          
            
          Object.keys(this.infoSlot).forEach((k) => {
            var item = this.infoSlot[k];
            var people = "0/"+item.total;
            var color =  this.colors[this.statuses.indexOf("waitingForBooking")];
            if(item.isEmpty == true && item.published == true){
              var teacher = "";
              var serviceId = item.serviceId;
              if(this.teacherNameList[serviceId] != null){
                teacher = this.teacherNameList[serviceId];
              }
              events.push({
                  name: item.name,
                  status: "waitingForBooking",
                  teacher:  teacher,
                  total: item.total,
                  start: item.start,
                  end: item.end,
                  price: item.price,
                  serviceId: item.serviceId,
                  paymentMethod: "",
                  username: "",
                  noPeople: people,
                  color: color,
                  timed: true,
                });
            }
        });
        }

        
        if(isAddWaitingForPayment) {
          var neworders2 = this.orders.filter(item => item.status == "waitingForPayment");

          neworders2.map(item => {
            var people = ""+item.amount + "/"+item.serviceDateTimeId.quantity;
            var color =  this.colors[this.statuses.indexOf(item.status)];

            var teacher = "";
            if(item.serviceId){
              if(item.serviceId.teacher){
                if(item.serviceId.teacher.nameZh && item.serviceId.teacher.nameEng){
                  teacher = (this.lang == 'tc') ? item.serviceId.teacher.nameZh : item.serviceId.teacher.nameEng;
                }
              }
            }
            var method = "";
            if(item.paymentMethod){
              if(item.paymentMethod.nameZh && item.paymentMethod.nameEng){
                method = (this.lang == 'tc') ? item.paymentMethod.nameZh : item.paymentMethod.nameEng;
              }
            }

              var json0 = {
                  name: item.serviceId.name,
                  status: item.status,
                  teacher: teacher,
                  total: item.serviceDateTimeId.quantity,
                  start: this.convertExtraTime(item.extra, item.serviceDateTimeId, true),
                  end: this.convertExtraTime(item.extra, item.serviceDateTimeId, false),
                  price: item.price,
                  serviceId: item.serviceDateTimeId.serviceId,
                  paymentMethod: method,
                  username: item.userId == null ? "" : item.userId.name,
                  noPeople: people,
                  color: color,
                  timed: true,
                  order: item,
                  mode: this.getOrderMode(item),
                }

                events.push(json0)
                //console.log(JSON.stringify(json0));
              })
              
        }

        if(isAddWaitingForConfirm) {
          var neworders3 = this.orders.filter(item => item.status == "waitingForConfirm");

          neworders3.map(item => {
            var people = ""+item.amount + "/"+item.serviceDateTimeId.quantity;
            var color =  this.colors[this.statuses.indexOf(item.status)];

            var teacher = "";
            if(item.serviceId){
              if(item.serviceId.teacher){
                if(item.serviceId.teacher.nameZh && item.serviceId.teacher.nameEng){
                  teacher = (this.lang == 'tc') ? item.serviceId.teacher.nameZh : item.serviceId.teacher.nameEng;
                }
              }
            }
            var method = "";
            if(item.paymentMethod){
              if(item.paymentMethod.nameZh && item.paymentMethod.nameEng){
                method = (this.lang == 'tc') ? item.paymentMethod.nameZh : item.paymentMethod.nameEng;
              }
            }

                events.push({
                  name: item.serviceId.name,
                  status: item.status,
                  teacher: teacher,
                  total: item.serviceDateTimeId.quantity,
                  start: this.convertExtraTime(item.extra, item.serviceDateTimeId, true),
                  end: this.convertExtraTime(item.extra, item.serviceDateTimeId, false),
                  price: item.price,
                  serviceId: item.serviceDateTimeId.serviceId,
                  paymentMethod: method,
                  username: item.userId == null ? "" : item.userId.name,
                  noPeople: people,
                  color: color,
                  timed: true,
                  order: item,
                  mode: this.getOrderMode(item),
                })
              })
        }


        if(isAddConfirmed){

          var neworders4 = this.orders.filter(item => item.status == "confirmed");

          neworders4.map(item => {
            var people = ""+item.amount + "/"+item.serviceDateTimeId.quantity;
            var color =  this.colors[this.statuses.indexOf(item.status)];
            var teacher = "";
            if(item.serviceId){
              if(item.serviceId.teacher){
                if(item.serviceId.teacher.nameZh && item.serviceId.teacher.nameEng){
                  teacher = (this.lang == 'tc') ? item.serviceId.teacher.nameZh : item.serviceId.teacher.nameEng;
                }
              }
            }
            var method = "";
            if(item.paymentMethod){
              if(item.paymentMethod.nameZh && item.paymentMethod.nameEng){
                method = (this.lang == 'tc') ? item.paymentMethod.nameZh : item.paymentMethod.nameEng;
              }
            }

                events.push({
                  name: item.serviceId.name,
                  status: item.status,
                  teacher: teacher,
                  total: item.serviceDateTimeId.quantity,
                  start: this.convertExtraTime(item.extra, item.serviceDateTimeId, true),
                  end: this.convertExtraTime(item.extra, item.serviceDateTimeId, false),
                  price: item.price,
                  serviceId: item.serviceDateTimeId.serviceId,
                  paymentMethod: method,
                  username: item.userId == null ? "" : item.userId.name,
                  noPeople: people,
                  color: color,
                  timed: true,
                  order: item,
                  mode: this.getOrderMode(item),
                })
              })
          
          
        }

       

        this.events = events
      },

      async getAllPaymentMethod () {
      let apiTarget = (this.permissions == 'admin') ? '/paymentMethod' : '/paymentMethod/published'

      try {
        let result = await api.get(apiTarget) 

        if (result) {
          this.paymentMethodList = result.data
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
    },
    
    

    async onConfirmTimePressed () {
      let result = false
      var userId = this.selectedEvent.order.userId._id;
      var orderId = this.selectedEvent.order._id;
                
      try {
        var toUpdated = this.selectedEvent.order;
        toUpdated["status"] = "waitingForConfirm";
          result = await api.patch('/'+ 'orders' +'/' + this.selectedEvent.order._id, toUpdated)

          if(result) {
              let targetI18n = 'global_alert.success_edit'
              this.PUSH_GLOBAL_SNACK_MESSAGES({
                  message: this.$t( targetI18n ),
                  icon   : 'mdi-check',
              })
              this.selectedOpen = false;
              this.updateEventStatus(this.selectedEvent.order._id, "waitingForConfirm", true);
              this.sendAutoMessage(userId, "Available", orderId);
          }
      } catch (error) {
          this.PUSH_GLOBAL_SNACK_MESSAGES({
              message: error.response.data.message,
              icon   : 'mdi-close',
          })
      }
      
    },

    async sendAutoMessage(userId, action, orderId,) {
      console.log(userId);
            
      try {
        await api.post('/message/auto', {
            "to"      : userId,
            "content" : action+"_"+orderId,
          })
      } catch (error) {
          this.PUSH_GLOBAL_SNACK_MESSAGES({
              message: error.response.data.message,
              icon   : 'mdi-close',
          })
      }
    },

    async onAcceptOrderPressed () {
      let result = false
      var userId = this.selectedEvent.order.userId._id;
      var orderId = this.selectedEvent.order._id;
                
      try {
        var toUpdated = this.selectedEvent.order;
        toUpdated["status"] = "confirmed";
          result = await api.patch('/'+ 'orders' +'/' + this.selectedEvent.order._id, toUpdated)

          if(result) {
              let targetI18n = 'global_alert.success_edit'
              this.PUSH_GLOBAL_SNACK_MESSAGES({
                  message: this.$t( targetI18n ),
                  icon   : 'mdi-check',
              })
              this.selectedOpen = false;
              this.updateEventStatus(this.selectedEvent.order._id, "confirmed", true);
              this.sendAutoMessage(userId, "Confirmed", orderId);
          }
      } catch (error) {
          this.PUSH_GLOBAL_SNACK_MESSAGES({
              message: error.response.data.message,
              icon   : 'mdi-close',
          })
      }
    },

    async updateDialogStatus(newobject){
      console.log(newobject);
      
      var id = this.selectedEvent.order._id;
      //var oldstatus = this.selectedEvent.order.status;
      this.selectedOpen = false;
      this.updateEventStatus(id, newobject, false);
    },

    async updateEventStatus(id, newobject, isStatus) {
      var order = null;
      this.orders.map(item => {
                var orderId = item._id;
                if(id == orderId){
                  order = item;
                }
              })

      if(order != null){
        var index = this.orders.indexOf(order);
        if(isStatus){
          order["status"] = newobject;
        } else {
          order["status"] = newobject.status;
          order.extra.startAt = newobject.extra.startAt;
          order.extra.duration = newobject.extra.duration;
          order.price = newobject.price;
        }

        if(order["status"] == "cancelled"){
          this.orders.splice(index,1);
        } else {
          this.orders[index] = order;
        }
        
        this.resetServiceDateTimeList();
        this.resetCountOrder();
        this.updateEvents(this.status);

      }
    },

    async onShareCalendarPress() {
      var isValid = validator.validate(this.emailShare);
      console.log("Email "+this.emailShare + " "+isValid);
      if(isValid){
        try{
              this.textLoadingShare = "Loading...";
              let result = await api.post('/merchants/shareCalendar', {"email": this.emailShare});
              //console.log(result)

              if(result){
                
                this.isShareShown = false;
                this.emailShare = "";
                this.textLoadingShare = "";

                this.PUSH_GLOBAL_SNACK_MESSAGES({
                  message: this.$t('global_alert.success_share_calendar'),
                  icon   : 'mdi-close',
              });

                if(result.data["isFirstCreated"] == true){
                  this.initCalendarEvent();
                }
              }
          } catch (error) {
              this.PUSH_GLOBAL_SNACK_MESSAGES({
                  message: error.response.data.message,
                  icon   : 'mdi-close',
              });
              this.isShareShown = false;
              this.emailShare = "";
              this.textLoadingShare = "";
          }
      } else {
        this.textLoadingShare = "Wrong Email";
      }
    },

    async initCalendarEvent() {
      try{
        await api.post('/merchants/initCalendarEvent', {});
        //console.log(result)

      } catch (error) {
        console.log(error)
      }
    },


      
  },
  data     : () => ({
    type: 'month',
    types: ['month', 'week', 'day'],
    mode: 'column',
    modes: ['stack', 'column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: '',
    events: [],
    colors: ['#ffffff','#d8e2f3', '#ffa9a3',"#ffd966", '#c3e0b2'],
    statuses: ['all', 'waitingForBooking', 'waitingForPayment', 'waitingForConfirm', 'confirmed'],
    indexStatus: 0,
    status: 'all',
    paymentMethodList: [],
    serviceList: {},
    serviceDateTimeList: [],
    merchantId: "",
    teacherNameList: {},
    noTotalWaitingForPayment: 0,
    noTotalWaitingForConfirm: 0,
    noTotalConfirmed: 0,
    isShareShown: false,
    emailShare: "",
    textLoadingShare: "",
    
    infoSlot: {},
    selectedEvent: {},
    selectedOpen: false,
    selectedElement: null,
    typeToLabel: {
        month: 'common.month',
        week: 'common.week',
        day: 'common.day',
      },
      statusToLabel: {
        all: 'order.all',
        waitingForBooking: 'order.waiting_booking',
        waitingForPayment: 'order.waiting_payment',
        waitingForConfirm: 'order.waiting_confirm',
        confirmed: 'order.confirmed',
      },

      statusi18: [
            {i18n: 'order.all',value: 'all'},
            {i18n: 'order.waiting_booking',value: 'waitingForBooking'},
            {i18n: 'order.waiting_payment',value: 'waitingForPayment'},
            {i18n: 'order.waiting_confirm',value: 'waitingForConfirm'},
            {i18n: 'order.confirmed',value: 'confirmed'},
        ],

      typei18: [
            {i18n: 'common.month',value: 'month'},
            {i18n: 'common.week',value: 'week'},
            {i18n: 'common.day',value: 'day'},
        ],
        statusArr               : [
      { i18n : 'order.waiting_payment', value : 'waitingForPayment' },
      { i18n : 'order.waiting_confirm', value : 'waitingForConfirm' },
      { i18n : 'order.confirmed', value : 'confirmed' },
      { i18n : 'order.cancelled', value : 'cancelled' },
    ],
    
    orders                       : [],
    totalMerchant                : '',
    lastWeekMerchant             : 0,
    LastBeforeMerchant           : 0,
    comparedLastWeekMerchant     : null,
    sixMonthsMerchant            : 0,
    totalUser                    : '',
    lastWeekUser                 : 0,
    LastBeforeUser               : 0,
    comparedLastWeekUser         : null,
    sixMonthsUser                : 0,
    totalPackageOrder            : '',
    lastWeekPackageOrder         : 0,
    LastBeforePackageOrder       : 0,
    sixMonthsPackageOrder        : 0,
    comparedLastWeekPackageOrder : null,
    totalServiceOrder            : '',
    lastWeekServiceOrder         : 0,
    LastBeforeServiceOrder       : 0,
    comparedLastWeekServiceOrder : null,
    sixMonthsServiceOrder        : 0,
    lastWeekTotalTransactions    : 0,
    LastBeforetotalTransactions  : 0,
    sixMonthsTransactions        : 0,
    comparedTransactions         : null
  })

  /*

  <v-btn
        icon
        class="ma-2"
        @click="getAllServiceOrder"
      >
        <v-icon>mdi-refresh</v-icon>

      </v-btn>

Object.keys(this.infoSlot).forEach((k) => {
            var item = this.infoSlot[k];
            var people = ""+item.noConfirmed + "/"+item.total;
            var color =  this.colors[this.statuses.indexOf("confirmed")];
            if(item.noConfirmed > 0){
              events.push({
                  name: item.name,
                  status: "confirmed",
                  teacher:  item.teacher,
                  total: item.total,
                  start: item.start,
                  end: item.end,
                  price: item.price,
                  serviceId: item.serviceId,
                  paymentMethod: "",
                  username: "",
                  noPeople: people,
                  color: color,
                  timed: true,
                });
            }
            
              
          })

@refresh="getAllOrders"

  colors: ['#ffffff','#d8e2f3', '#ffa9a3',"#ffd966", '#c3e0b2'],
  statuses: ['all', 'waitingForBooking', 'waitingForPayment', 'waitingForConfirm', 'confirmed'],
        
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="type"
      ></v-select>

            <v-select
        v-model="status"
        :items="statuses"
        @change="updateEvents"
        :background-color="this.colors[this.statuses.indexOf(this.status)]"
        dense
        outlined
        hide-details
        class="ma-2"
        label="status"
      >

      </v-select>


      <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ statusToLabel[status] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="status = 'all'">
                <v-list-item-title>All</v-list-item-title>
              </v-list-item>
              
              <v-list-item @click="status = 'waitingForBooking'">
                <v-list-item-title>Waiting For Booking</v-list-item-title>
              </v-list-item>

              <v-list-item @click="status = 'waitingForPayment'">
                <v-list-item-title>Waiting For Payment</v-list-item-title>
              </v-list-item>

              <v-list-item @click="status = 'waitingForConfirm'">
                <v-list-item-title>Waiting For Confirm</v-list-item-title>
              </v-list-item>

              <v-list-item @click="status = 'confirmed'">
                <v-list-item-title>Confirmed</v-list-item-title>
              </v-list-item>
              
            </v-list>
          </v-menu>


          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>

              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              
            </v-list>
          </v-menu>
  
  */
}
</script>
